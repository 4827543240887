<template>
  <div>

    <page-section
        :background-color="wsLIGHTACCENT"
        subtitle="homepage.pricing.subtitle"
        no-padding
    >
      <template #title>
        <h4 :style="`color : ${wsDARKER}; font-size: ${ !SM ? '42' : '34' }px  `"
            style=" margin-left: auto; margin-right: auto; "
            class="text-center mt-8 px-6">
          {{ $t('homepage.pricing.title') }}
        </h4>
      </template>
      <div class="d-flex justify-center px-6"
           :class="[{'mb-16' : !SM}]"
      >
        <ws-select
            v-model="selectedPeriod"
            :items="paymentDurationSelect"
            background-color="white"
            :width="!SM ? '250px' : '100%'"
        />

      </div>
      <v-row class="px-6 mt-0 " v-if="SM"  no-gutters>
        <v-col cols="6" class="pr-1">
          <ws-select
              v-model="planOne"
              :items="planOneSelect"
              background-color="white"
          />
        </v-col>
        <v-col cols="6" class="pl-1">
          <ws-select
              v-model="planTwo"
              :items="planTwoSelect"
              background-color="white"
              :width="!SM ? '250px' : '100%'"
          />
        </v-col>
      </v-row>

      <v-simple-table
          v-if="SM"
          class="px-6"
          style="background-color: transparent; border-radius: 0"
          :style="`border-bottom : 1px solid ${wsLIGHTACCENT}; `"
      >
        <tbody>
        <!-- Prices -->
        <tr style="background-color: transparent">
          <td :style="`border-right : 1px solid ${wsLIGHTACCENT};  border-left : 1px solid ${wsLIGHTACCENT};  `"
              style="background-color: white"
              align="center"
          >
            <h3 :style="`color : ${wsDARKER}`" class="text-center mt-7"  style="font-size: 16px" >{{getPlanName(planOne)}}</h3>
            <h1 :style="`color : ${wsDARKER}`"  style="font-size: 24px" class="d-flex align-center justify-center ">
              ${{ getPrice(plansPrice[planOne])}}
            </h1>
            <div class="ml-2 font-weight-regular" style="font-size: 13px"> {{ priceText }}  </div>
            <h4 v-if="selectedPeriod > 1" :style="`color : ${wsACCENT}`" class="py-2 pb-3"  style="font-size: 16px">
              {{ $t('Discount') }}  ${{ getDiscount(plansPrice[planOne])}}
            </h4>
            <v-btn
                :to="localeLink('register')"
                :color="wsATTENTION"
                class="noCaps mt-4 text-wrap"
                style="font-size: 13px; text-wrap: normal"
                elevation="0" height="60"

                block dark
            >
              <span class="text-center" style="font-size: 13px">{{ $t('homepage.pricing.try') }} <br> {{ $t('homepage.pricing.forFree') }} </span>
            </v-btn>
            <h5 class="mt-2 pb-7 text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('homepage.pricing.for30Days') }}</h5>
          </td>
          <td
              style="background-color: white"
              :style="`border-right : 1px solid ${wsLIGHTACCENT};`"
              align="center"
          >
            <h3 :style="`color : ${wsDARKER}`" class="text-center mt-7"  style="font-size: 16px" >{{getPlanName(planTwo)}}</h3>
            <h1 :style="`color : ${wsDARKER}`"  style="font-size: 24px" class="d-flex align-center justify-center ">
              ${{ getPrice(plansPrice[planTwo])}}
            </h1>
            <div class="ml-2 font-weight-regular" style="font-size: 13px"> {{ priceText }}  </div>
            <h4 v-if="selectedPeriod > 1" :style="`color : ${wsACCENT}`" class="py-2 pb-3"  style="font-size: 16px">
              {{ $t('Discount') }}  ${{ getDiscount(plansPrice[planTwo])}}
            </h4>
            <v-btn
                :to="localeLink('register')"
                :color="wsATTENTION"
                class="noCaps mt-4 text-wrap"
                style="font-size: 13px; text-wrap: normal"
                elevation="0" height="60"

                block dark
            >
              <span class="text-center" style="font-size: 13px">{{ $t('homepage.pricing.try') }} <br> {{ $t('homepage.pricing.forFree') }} </span>
            </v-btn>
            <h5 class="mt-2 pb-7 text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('homepage.pricing.for30Days') }}</h5>
          </td>
        </tr>
        <tr>
          <td :style="`border-right : 1px solid ${wsLIGHTACCENT};  border-left : 1px solid ${wsLIGHTACCENT};  `"
              colspan="2" style="background: white; height: 70px" class="px-2">
            <h5 style="font-size: 13px">
              {{ $t('homepage.pricing.mainFunctions') }}
            </h5>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

      <v-sheet v-if="!SM" color="transparent" :height="selectedPeriod === 1 ? '201' : '245'" />
    </page-section>
    <page-section
        background-color="#F2F8FB"
        no-padding
    >
      <v-simple-table
           class="pt-0"
          :class="[{'px-6' : SM}]"
          style="background-color: transparent"
          :style="!SM ? `margin-top: -${selectedPeriod === 1 ? 231 : 275}px; ` : ''"
      >
        <tbody>
        <!-- Prices -->
        <tr v-if="!SM" style="background-color: transparent">

          <td :style="`border : 1px solid ${wsLIGHTACCENT}; `"
              style="border-top-left-radius: 16px;
              background-color: white"
              align="center"
              width="282px"
          />
          <td :style="`border-top : 1px solid ${wsLIGHTACCENT}; border-bottom : 1px solid ${wsLIGHTACCENT}; border-right : 1px solid ${wsLIGHTACCENT};`"
              style="background-color: white;"
              class="pt-10 px-4 pb-7"
              align="center"
              width="285px"
          >
            <h3 :style="`color : ${wsDARKER}`" class="text-center"  style="font-size: 19px" >{{$t('homepage.pricing.base')}}</h3>
            <h1 :style="`color : ${wsDARKER}`"  style="font-size: 42px" class="d-flex align-center justify-center ">
              ${{ getPrice(11)}} <div class="ml-2 font-weight-regular" style="font-size: 16px"> {{ priceText }}  </div>
            </h1>

            <h4 v-if="selectedPeriod > 1" :style="`color : ${wsACCENT}`" class="py-2 pb-3"  style="font-size: 16px">
                {{ $t('Discount') }}  ${{ getDiscount(11)}}
            </h4>


            <v-btn style="font-size: 13px" :to="localeLink('register')"  :color="wsATTENTION" block dark class="noCaps mt-1" elevation="0" height="40">
              {{ $t('homepage.pricing.tryForFree') }}
            </v-btn>
            <h5 class="mt-2 text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('homepage.pricing.for30Days') }}</h5>
          </td>
          <td :style="`border-top : 1px solid ${wsLIGHTACCENT}; border-bottom : 1px solid ${wsLIGHTACCENT}; border-right : 1px solid ${wsLIGHTACCENT};  `"
              style="background-color: #ECF5F9;"
              class="pt-10 px-4 pb-7"
              align="center"
              width="285px"
          >
            <h3 :style="`color : ${wsDARKER}`" class="text-center"  style="font-size: 19px" >{{$t('homepage.pricing.standard')}}</h3>
            <h1 :style="`color : ${wsDARKER}`"  style="font-size: 42px" class="d-flex align-center justify-center ">
              ${{ getPrice(37)}} <div class="ml-2 font-weight-regular" style="font-size: 16px"> {{ priceText }}  </div>
            </h1>

            <h4 v-if="selectedPeriod > 1" :style="`color : ${wsACCENT}`" class="py-2 pb-3"  style="font-size: 16px">
              {{ $t('Discount') }}  ${{ getDiscount(37)}}
            </h4>
            <v-btn style="font-size: 13px" :to="localeLink('register')" block :color="wsATTENTION" dark class="noCaps mt-1" elevation="0" height="40">
              {{ $t('homepage.pricing.tryForFree') }}
            </v-btn>
            <h5 class="mt-2 text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('homepage.pricing.for30Days') }}</h5>
          </td>

          <td :style="`border-top : 1px solid ${wsLIGHTACCENT}; border-bottom : 1px solid ${wsLIGHTACCENT}; border-right : 1px solid ${wsLIGHTACCENT};  `"
              style="border-top-right-radius: 16px; background-color: #E1EBF0;"
              class="pt-10 px-4 pb-7"
              align="center"
              width="285px"
          >
            <h3 :style="`color : ${wsDARKER}`" class="text-center"  style="font-size: 19px" >{{$t('homepage.pricing.premium')}}</h3>
            <h1 :style="`color : ${wsDARKER}`"  style="font-size: 42px" class="d-flex align-center justify-center ">
              ${{ getPrice(79)}} <div class="ml-2 font-weight-regular" style="font-size: 16px"> {{ priceText }}  </div>
            </h1>

            <h4 v-if="selectedPeriod > 1" :style="`color : ${wsACCENT}`" class="py-2 pb-3"  style="font-size: 16px">
              {{ $t('Discount') }}  ${{ getDiscount(79)}}
            </h4>
            <v-btn style="font-size: 13px" :to="localeLink('register')" block :color="wsATTENTION" dark class="noCaps mt-1" height="40" elevation="0">
              {{ $t('homepage.pricing.tryForFree') }}
            </v-btn>
            <h5 class="mt-2 text-center" :style="`color : ${wsDARKLIGHT}`">{{ $t('homepage.pricing.for30Days') }}</h5>
          </td>

        </tr>

        <tr
            @mouseover="tableHover(i , true)"
            @mouseleave="tableHover(i,false)"
            v-for="(feature,i) in pricingFeatures"  :key="i">
          <td :style="`
          border-left : 1px solid ${wsLIGHTACCENT};
          border-bottom : 1px solid ${wsLIGHTACCENT};
          border-right : 1px solid ${wsLIGHTACCENT};
          ${cellBackground(null,i)}
          ${i === pricingFeatures.length -1 ? 'border-bottom-left-radius : 0px' : null }
          `"
              style="background-color: white; height: 70px !important"
              class="pa-2 "
              valign="center"
              width="282px"
          >
            <div class="d-flex justify-space-between align-center ">

              <h5 :style="`color : ${wsDARKER};font-size: ${SM ? 13 : 14}px;`" class="font-weight-regular">{{ feature.name }}</h5>

              <ws-tooltip v-if="feature.tooltip" :text="$t(feature.tooltip)">
                <v-icon :color="wsACCENT">mdi-information-outline</v-icon>
              </ws-tooltip>
            </div>
          </td>

          <template v-if="!SM">
            <td
                v-for="(item,j) in feature.items " :key="'feature' +i + '_' +j"
                :style="`
              border-bottom : 1px solid ${wsLIGHTACCENT};
              border-right : 1px solid ${wsLIGHTACCENT};
              ${cellBackground(j,i)}
               ${i === pricingFeatures.length -1 && j === 2 ? 'border-bottom-right-radius : 0px' : null }
               `"
                align="center"
                width="286px"
            >
              <v-icon
                  v-if="item.isBool && item.isTrue"
                  color="green lighten-1"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <h5 v-else :style="`color : ${wsACCENT}`" >{{ item.text }}</h5>
            </td>
          </template>



          <template v-else>
            <td
                :style="`
              border-bottom : 1px solid ${wsLIGHTACCENT};
              border-right : 1px solid ${wsLIGHTACCENT};
              ${cellBackground(0,i)}
               'border-bottom-right-radius : 0px
               `"
                align="center"
                width="286px"
            >
              <v-icon
                  v-if="feature.items[planOne].isBool && feature.items[planOne].isTrue"
                  color="green lighten-1"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <h5 v-else :style="`color : ${wsACCENT}`" >{{ feature.items[planOne].text }}</h5>
            </td>

            <td
                :style="`
              border-bottom : 1px solid ${wsLIGHTACCENT};
              border-right : 1px solid ${wsLIGHTACCENT};
              ${cellBackground(0,i)} `"
                align="center"
                width="286px"
            >
              <v-icon
                  v-if="feature.items[planTwo].isBool && feature.items[planTwo].isTrue"
                  color="green lighten-1"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <h5 v-else :style="`color : ${wsACCENT}`" >{{ feature.items[planTwo].text }}</h5>
            </td>


          </template>





        </tr>

        </tbody>

      </v-simple-table>
      <v-row :no-gutters="SM" class="mt-10 " :class="[{'px-6' : SM}]">
        <v-col md="6" cols="12">
           <v-sheet class="d-flex  wsRoundedHalf py-8 px-4" style="border : 3px solid #D7E5F0; background-color: transparent" >
             <v-img
                 src="@/assets/illustrations/vaulanteer.png"
                 class="mr-4"
                 height="62px" width="62px"
             />
             <div>
               <h3 :style="`color : ${wsDARKER}`">
                 {{ $t('homepage.pricing.social.title') }}</h3>
               <v-btn
                   height="40"
                   @click="$store.state.homepage.displayContactForm = true"
                   outlined :color="wsDARKER" class="noCaps mt-3">
                 {{ $t('homepage.pricing.social.apply') }}
               </v-btn>
             </div>

           </v-sheet>
        </v-col>
        <v-col md="6" cols="12" :class="[{'mt-6' : SM}]">
          <v-sheet color="#D7E5F0" class="d-flex  wsRoundedHalf py-8 px-4" height="174"  >
            <v-img
                src="@/assets/illustrations/space.png"
                class="mr-4"
                height="62px" width="62px"
            />
            <div>
              <h3 :style="`color : ${wsDARKER}`" class="pr-6">
                {{ $t('homepage.pricing.corporate.title') }}</h3>
              <v-btn
                  height="40"
                  @click="$store.state.homepage.displayContactForm = true"
                  :color="wsATTENTION" dark class="noCaps mt-3" elevation="0">
                {{ $t('homepage.pricing.corporate.apply') }}
              </v-btn>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

      <h1 :style="`color : ${wsDARKER}; ${SM ? 'font-size: 24px;' : ''} `"
          style="max-width: 1000px; margin-left: auto; margin-right: auto;"
          class="text-center mt-10 mb-6">
        {{ $t('homepage.pricing.faq.title') }}
      </h1>

      <div class="d-flex justify-center pb-16">
        <div   :class="[{'px-6' : SM }]" >
          <div v-for="(item, i ) in faqItems" :key="`faq_${i}`">

            <v-sheet
                class="px-4 wsRoundedHalf mt-2"
                max-width="782"
            >
              <div
                  @click="item.expanded = !item.expanded"
                  class="d-flex justify-space-between align-center pointer px-4"
                  :style="`height: ${!SM ? 72 : 70}px`"
              >
                <h3 class="mr-3"  :style="`color : ${wsDARKER}; ${SM ? 'font-size: 13px;' : ''} ` "> {{ $t(item.title) }} </h3>
                <v-icon
                    :style="item.expanded ? 'transform: rotate(180deg)' : null "
                    style="transition: all 0.3s ease;"
                    :color="wsDARKER">mdi-chevron-down</v-icon>
              </div>
              <v-expand-transition>
                <div v-if="item.expanded" class="pb-5">
                  <v-divider class="pb-5" :style="`border-color : ${wsBACKGROUND}`" />
                  <h4 :style="`color : ${wsDARKER};  ${SM ? 'font-size: 13px;' : ''} `" class="font-weight-regular px-4">{{ $t(item.text) }}</h4>
                </div>
              </v-expand-transition>
            </v-sheet>
          </div>

        </div>
      </div>

    </page-section>

  </div>
</template>

<script>
export default {
  name: "HomeAbout",
  data() {
    return {
      hoverIndex : false,
      selectedPeriod : 1,
      planOne : 0,
      planTwo : 1,
      plansPrice : [11,37,79],
      pricingFeatures : [
        { name : 'Хмарне сховище файлів' , items : [
            { isBool : false, text : '25 GB'  },
            { isBool : false, text : '150 GB'},
            { isBool : false, text : '350 GB'},
          ]
        },
        { name : 'Кількість менеджерів / користувачів' , items : [
            { isBool : false, text : '1/200'  },
            { isBool : false, text : '25/1 000'},
            { isBool : false, text : '50/3 500'},
          ]
        },
        { name : 'Персональна цільова сторінка сайту з курсами' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Система обліку користувачів' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Конструктор веб-сайтів' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Платіжна система' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        {
          name : 'Автоматизація доступу до курсу після оплати' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Облік платежів і статистика' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'СRM система, звіти та статистика користувачів' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Чат-бот Telegram (оповіщення, інформаційні бюлетені, особистий графік користувача / менеджера, оплата послуг)' , items : [
            { isBool : true , isTrue : false },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Система освіти навчальних курсів WeStudy' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'редактор курсу (створення та редагування курсів)' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'основні компоненти (лекції, завдання, тести, файли, відео, відео зустрічі та ін)' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'тарифікація курсу та обмеження доступу відпвовідно до пакетних планів' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'система імпорту студентів' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },

        { name : 'Система освіти навчальних курсів WeStudy' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },

        { name : 'Система освіти навчальних курсів WeStudy' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Особистий кабінет адміністратора' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Особистий кабінет студента' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Особистий кабінет менеджера' , items : [
            { isBool : true , isTrue : false },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        { name : 'Вбудований месенджер для спілкування' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        {
          name : 'Система сповіщень' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        {
          name : 'Система Email розсилок та статистика отримання' , items : [
            { isBool : true , isTrue : false },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        {
          name : 'Конструктор Email листів' , items : [
            { isBool : true , isTrue : false },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },
        {
          name : 'Самореєстрація користувачів, реєстрація на курс' , items : [
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
            { isBool : true , isTrue : true },
          ]
        },







      ],
      faqItems : [
        { title : 'homepage.pricing.faq.question_1.title' , text : 'homepage.pricing.faq.question_1.text' , expanded : false },
        { title : 'homepage.pricing.faq.question_2.title' , text : 'homepage.pricing.faq.question_2.text' , expanded : false },
        { title : 'homepage.pricing.faq.question_3.title' , text : 'homepage.pricing.faq.question_3.text' , expanded : false },
        { title : 'homepage.pricing.faq.question_4.title' , text : 'homepage.pricing.faq.question_4.text' , expanded : false },
        { title : 'homepage.pricing.faq.question_5.title' , text : 'homepage.pricing.faq.question_5.text' , expanded : false }
      ]
    }
  },
  computed : {
    plansSelect() {
      return [
        { text : this.$t('homepage.pricing.base') , value : 0    },
        { text : this.$t('homepage.pricing.standard') , value : 1  },
        { text : this.$t('homepage.pricing.premium') , value : 2  }
      ]
    },
    planOneSelect() {
      let items = this.plansSelect
      return items = items.filter(el => el.value !== this.planTwo)
    },
    planTwoSelect() {
      let items = this.plansSelect
      return items = items.filter(el => el.value !== this.planOne)
    },
    priceText() {

      if ( this.selectedPeriod === 36 ) {
        return  this.$tc('perYears' , 3)
      }

      if ( this.selectedPeriod === 24 ) {
        return  this.$tc('perYears' , 2)
      }

      if ( this.selectedPeriod === 12 ) {
        return this.$tc('perYears' , 1)
      }

      if ( this.selectedPeriod > 1 ) {
        return this.$tc('perMonths' , this.selectedPeriod)
      }

      if ( this.SM ) {
        return this.$t('perMonth')
      }
      return  `/ ${this.$t('perMonth') }`

    },
    paymentDurationSelect() {
      return [
        { text : `${this.$tc('month', 1  , { n : 1  }) }, (${this.$t('noDiscount')})`         , value : 1  },
        { text : `${this.$tc('month', 3  , { n : 3  }) }, -5%`    , value : 3  },
        { text : `${this.$tc('month', 6  , { n : 6  }) }, -7%`    , value : 6  },
        { text : `${this.$tc('month', 12 , { n : 12 }) }, -10%`   , value : 12 },
        { text : `${this.$tc('month', 24 , { n : 24 }) }, -15%`   , value : 24 },
        { text : `${this.$tc('month', 36 , { n : 36 }) }, -20%`   , value : 36 }
      ]
    },
  },
  methods : {
    getPlanName(index) {
      return this.plansSelect.find(el => el.value === index ).text
    },
    tableHover(index , value ) {
      this.hoverIndex = value ? index : false
    },
    getDiscount(perMonth) {
      let discounts = {
        1 : 0    ,
        3 : 0.05 ,
        6 : 0.07 ,
        12 : 0.1 ,
        24 : 0.15,
        36 : 0.2
      }
      let price = perMonth * this.selectedPeriod * discounts[this.selectedPeriod]
      return price.toFixed()
    },
    getPrice(perMonth) {
      return (perMonth * this.selectedPeriod) - this.getDiscount(perMonth)
    },
    cellBackground(index , rowIndex) {
      let color = 'white'
      if ( rowIndex === this.hoverIndex ) {
        return `background-color : #d5e0e5;`
      }
      if ( index === null ) {
        color = rowIndex !== this.hoverIndex ? 'white' :  '#e6eff3'
      }
      if ( index === 0 ) {
        color =  rowIndex !== this.hoverIndex ? 'white' :  '#e6eff3'
      }
      if ( index === 1 ) {
        color =  rowIndex !== this.hoverIndex ? '#ECF5F9' :  '#d5e0e5'
      }
      if ( index === 2 ) {
        color = rowIndex !== this.hoverIndex ? '#E1EBF0' :  '#c9dbe5'
      }
      console.log(rowIndex)
      return `background-color : ${color};`
    }
  }
}
</script>

<style scoped>
.hoverColor:hover {
  background: #eeb9b9 !important;
}
.section_first {
  background-image: url('~@/assets/img/bg-pages.jpg');
  background-position: center center;
  position: relative;
  background-size: cover;
}
</style>